import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { graphql } from "gatsby"
import $ from "jquery"
import Metatags from "../../components/metatags"
import Navbar from "../../components/navbar"
import Gallery from "../../components/projects-gallery"
import MobGallery from "../../components/mob-gallery-comp"
import Footer from "../../components/footer"

const KimptonVividora = ({ data }) => {
  const [windowWidth, setWindowWidth] = useState(0)

  const setVideoWindow = () => {
    setWindowWidth($(window).width())
  }

  useEffect(() => {
    setWindowWidth($(window).width())
    if (typeof window !== "undefined") {
      window.addEventListener("resize", setVideoWindow)
    }
  }, [])

  return (
    <>
      <Metatags
        title="daucodesigns - hotel kimpton vividora"
        description="detrás de cada producto, hay una historia"
        url="https://daucodesigns.com"
        image="https://daucodesigns.com/logo-daucodesigns.png"
        keywords="muebles, design"
      />
      <Navbar />
      <div className="height40" />
      <div className="height20" />
      <div id="project-header">
        <Container style={{ maxWidth: "1300px" }}>
          <Row>
            <Col>
              <div className="text-center projects">
                <h4 className="marcas-title nuestras-marcas-title">
                  hotel kimpton vividora
                </h4>
                <h5>barcelona</h5>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="height40" />
      </div>
      {windowWidth >= 768 ? (
        <Gallery
          imagesArray={data.projectImages.nodes}
          company="hotel kimpton vividora"
        />
      ) : (
        <MobGallery
          imagesArray={data.projectImages.nodes}
          ratio={765 / 1460}
          company="hotel kimpton vividora"
          windowWidth={windowWidth}
          leftArrow={data.leftArrow}
          rightArrow={data.rightArrow}
        />
      )}
      <div className="height40" />
      <Container>
        <Row>
          <Col>
            <div className="text-center projects">
              <h5>Una joya en el centro de Barcelona</h5>
            </div>
          </Col>
        </Row>
        <div className="height20" />
        <Row>
          <Col>
            <p>
              El hotel Kimpton Vividora es una pequeña joya en el centro
              histórico de Barcelona. Ubicado en la calle Duc, 15, entre las
              Ramblas y la Catedral, combina lo mejor de dos mundos: tradición y
              modernidad. El Interiorismo ha sido diseñado por El Equipo
              Creativo, que supieron conjugar a la perfección un estilo muy
              personal, un entorno cálido y acogedor con una calidad y nobleza
              de materiales. El resultado respira un lujo reposado que refleja a
              la perfección esa estilo de visitantes que hospeda el hotel.
            </p>

            <p>
              Para el Interiorismo de sus 165 habitaciones, restaurante, salones
              y terraza roof top, se combinaron muebles de marcas
              internacionales con desarrollos a medida, siempre buscando una
              integración con el entorno y la ubicación.
            </p>

            <p>
              Productos utilizados: Nani Marquina, Sancal, DePadova, Knoll,
              Lobstersday, Expormim, soluciones a medida en carpintería y mármol
              natural.
            </p>
          </Col>
        </Row>
      </Container>
      <div className="height40" />
      <div className="height40" />
      <Footer />
    </>
  )
}

export default KimptonVividora

export const query = graphql`
  {
    leftArrow: file(relativePath: { eq: "left.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    rightArrow: file(relativePath: { eq: "right.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    projectImages: allFile(
      filter: { relativePath: { regex: "/projects/kimpton-vividora/" } }
      sort: { fields: relativePath }
    ) {
      nodes {
        childrenImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
    }
  }
`
